import React from 'react'
import doctor from '../../images/doctor-profile-image.png'

export default function Profile() {
    return (
        <div
            id="profile"
            className="mt-20 lg:mt-0 bg-profile bg-cover bg-center"
        >
            <div
                className="container grid grid-cols-1 lg:grid-cols-2 mt-16 "
                style={{ alignItems: 'center' }}
            >
                <div>
                    <p className="font-bold text-blue-brand">
                        Dr. Winston Baldwin Duthurburu
                    </p>
                    <p className="font-bold  text-gray-brand">
                        Cirujano dentista
                    </p>
                    <p className="font-bold  text-gray-brand">COP 26843</p>
                    <h2 className="text-3xl text-black-brand font-bold mt-2">
                        Profesional de la Salud dental con amplia trayectoria.
                    </h2>
                    <p className="mt-4 text-gray-brand">
                        ¡Endodoncias, implantes, carillas, coronas de porcelana,
                        restauraciones y blanqueamientos! Si tiene algo que ver
                        con los dientes, el Dr. Baldwin es el indicado. Winston
                        es especialista en <span className='text-band-black font-semibold'>endodoncia y rehabilitación oral</span>, con
                        más 17 años de experiencia en las más prestigiosas
                        clínicas dentales del Perú, y cuenta con una gran
                        cantidad de pacientes satisfechos a largo de su
                        intachable trayectoria profesional.
                    </p>
                    <ul className="mt-9">
                        <li className="flex gap-4 items-center">
                            <span className="check-icon"></span>
                            <p className="font-semibold">
                                100% dedicado a la salud de sus pacientes
                            </p>
                        </li>
                        <li className="flex gap-4 items-center mt-4">
                            <span className="check-icon"></span>
                            <p className="font-semibold">
                                Especialista en distintas áreas odontológicas
                            </p>
                        </li>
                    </ul>
                    <a
                        target={'_blank'}
                        href="https://wa.me/51989868701?text=%C2%A1Hola!%20Acabo%20de%20visitar%20tu%20p%C3%A1gina%20y%20tengo%20una%20consulta.%20%F0%9F%91%8B"
                        className="font-semibold block mt-6 bg-blue-brand rounded-primary w-max px-4 py-2 pointer text-white font-monrope hover:shadow-lg"
                    >
                        Contáctame para una cita
                    </a>
                </div>
                <div>
                    <img src={doctor} alt="perfil" />
                </div>
            </div>
        </div>
    )
}
