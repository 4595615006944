import React from 'react'
import TestimoniesSwiper from './testimoniesSwiper'
import RightArrow from '../../images/long_right.svg'
import LeftArrow from '../../images/long_left.svg'

export default function Testimonies() {
    return (
        <div id="testimonies" className="mt-100">
            <h2 className="mt-4 font-bold text-3xl text-center">
                Nuestros pacientes
            </h2>
            <p className="text-gray-brand text-sm  mt-2  text-center">
                Conoce la opinión de nuestros pacientes
            </p>
            <div className="mt-12 bg-light-blue-brand pt-24  pb-5">
                <div className="container">
                    <TestimoniesSwiper />
                </div>
                <div className="flex gap-5 justify-end container">
                    <div className="prev">
                        <img
                            src={LeftArrow}
                            alt="prev"
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className="next">
                        <img
                            src={RightArrow}
                            alt="next"
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
