import React, { useEffect } from 'react'
import FeatureCard from './FeaturesCard'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper'
import 'swiper/css'

//images
import serviceImage1 from './../../images/service-image-1.png'
import serviceImage2 from './../../images/service-image-2.png'
import serviceImage3 from './../../images/service-image-3.png'
import serviceImage4 from './../../images/service-image-4.png'
import serviceImage5 from './../../images/service-image-5.png'
import serviceImage6 from './../../images/service-image-6.png'
const servicesList = [
    {
        img: serviceImage1,
        title: 'Endodoncia',
        text: 'La endodoncia es un procedimiento que tiene como finalidad preservar las piezas dentales dañadas, evitando así su pérdida.',
    },
    {
        img: serviceImage2,
        title: 'Cirugía Dental',
        text: 'Además de solucionar problemas dentales importantes, la cirugía oral nos permite corregir aquellos problemas referentes a la salud oral del paciente.',
    },
    {
        img: serviceImage3,
        title: 'Implantes Dentales',
        text: 'Los implantes dentales se utilizan para remplazar dientes perdidos. Nuestros implantes se ven, se sienten y funcionan como dientes naturales.',
    },
    {
        img: serviceImage4,
        title: 'Estética Dental',
        text: 'La estética dental se encarga de proporcionar armonía y belleza a la sonrisa',
    },
    {
        img: serviceImage5,
        title: 'Rehabilitación oral:',
        text: 'La rehabilitación oral devuelve la estética y armonía oral mediante prótesis dentales, siempre buscando una oclusión y función correcta.',
    },
    {
        img: serviceImage6,
        title: 'Blanqueamiento dental',
        text: 'El blanqueamiento dental es un tratamiento estético que logra reducir varios tonos el color original de los dientes, dejándolos más blancos y brillantes.',
    },
]
export default function Features() {
    return (
        <div className="mt-100">
            <p className="text-blue font-manrope font-bold text-blue-brand text-center">
                Servicios odontológicos
            </p>
            <h2 className="mt-4 font-bold text-3xl text-center">
                Conoce los servicios que servicios en Dental Baldwin
            </h2>
            <Swiper
                modules={[Navigation, Autoplay]}
                className="mt-10"
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 2500,
                }}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                    },
                    1024:{
                        slidesPerView: 4,
                    }
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                // navigation={{
                //     prevEl: '.prev',
                //     nextEl: '.next',
                // }}
            >
                {servicesList.map((element, index) => (
                    <SwiperSlide key={`service-card-item-${index}`}>
                        <FeatureCard
                            data={element}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
