import React from 'react'

export default function ServiceCard({ data }) {
    return (
        <div className="shadow-lg max-w-xs h-full rounded-b-md">
            <img
                src={data.img}
                alt="servicio"
                className="w-full object-cover"
            />
            <div className="px-10 py-5">
                <h3 className="text-black-brand font-bold ">{data.title}</h3>
                <p className="text-gray-brand text-xs mt-4">{data.text}</p>
            </div>
        </div>
    )
}
