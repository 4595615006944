import React, { useEffect } from 'react'
import Parallax from 'parallax-js' // Now published on NPM

//images
import doctor from './../../images/doctor-image.png'
import item1 from './../../images/item-1.png'
import item2 from './../../images/item-2.png'
import item3 from './../../images/item-3.png'

export default function Hero() {
    useEffect(() => {
        var scene = document.getElementById('scene')
        var parallaxInstance = new Parallax(scene, {
            relativeInput: true,
        })
        const onScroll = () => {
            console.log('setOffset', window.pageYOffset)
            if(window.pageYOffset > 0) {
                document.getElementById('header').style.backgroundColor = '#fff'
            }else{
                document.getElementById('header').style.backgroundColor = 'transparent'

            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    return (
        <div id="home" className="bg-hero bg-cover bg-center pt-20">
            <div className="container  grid grid-cols-1 lg:grid-cols-2 lg:min-h-hero items-center">
                <div className="my-16 lg:my-0">
                    <h1 className="font-bold text-2xl  lg:text-4xl text-blue-brand">
                    ¡Bienvenidos a Dental Baldwin! 
                    </h1>
                    <h2 className="text-lg lg:text-2xl font-bold">
                    Ofrecemos servicios odontológicos restauradores, estéticos, así como tratamientos quirúrgicos e implantes dentales.
                    </h2>
                    {/* <p className="text-gray-brand mt-3">
                        Bienvenidos a Dental Baldwin. Ofrecemos servicios
                        odontológicos restauradores, tratamientos quirúrgicos e
                        implantes dentales.
                    </p> */}
                    <button className="mt-6 font-semibold bg-blue-brand rounded-primary w-max px-4 py-2 pointer text-white font-monrope hover:shadow-lg">
                        <a
                            href="https://wa.me/51989868701?text=%C2%A1Hola!%20Acabo%20de%20visitar%20tu%20p%C3%A1gina%20y%20tengo%20una%20consulta.%20%F0%9F%91%8B"
                            target="_blank"
                        >
                            Reserva una cita
                        </a>
                    </button>
                </div>
                <ul id="scene" className="parallax relative hidden lg:block">
                    <li className="layer " data-depth="0.0">
                        <img src={doctor} />
                    </li>
                    {/* <li className="layer image-1" data-depth="0.40">
                        <img src={item1} />
                    </li>
                    <li className="layer image-1" data-depth="0.60">
                        <img src={item2} />
                    </li>
                    <li className="layer image-1" data-depth="1">
                        <img src={item3} />
                    </li> */}
                </ul>
            </div>
        </div>
    )
}
