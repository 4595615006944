import React from 'react'
//images
import serviceImage1 from './../../images/service-image-1.png'
import serviceImage2 from './../../images/service-image-2.png'
import serviceImage3 from './../../images/service-image-3.png'
import serviceImage4 from './../../images/service-image-4.png'
import serviceImage5 from './../../images/service-image-5.png'
import serviceImage6 from './../../images/service-image-6.png'
//icons
import wsp from './../../images/wsp-icon.svg'

import ServiceCard from './serviceCard'

const servicesList = [
    {
        img: serviceImage1,
        title: 'Blanqueamiento Dental',
        text: 'El blanqueamiento dental logra reducir varios tonos el color original de las piezas dentales, dejando los',
    },
    {
        img: serviceImage2,
        title: 'Cirugía Dental',
        text: 'La cirugía dental nos permite tratar con un gran aspecto de enfermedades, heridas y aspectos estéticos de la boca y los dientes.',
    },
    {
        img: serviceImage3,
        title: 'Implantes Dentales',
        text: 'Los implantes dentales se utilizan para remplazar dientes perdidos. Nuestros implantes se ven, se sienten y funcionan como dientes naturales.',
    },
    {
        img: serviceImage4,
        title: 'Estética Dental',
        text: 'La estética dental nos permite proporcionar armonía y belleza a tu sonrisa.',
    },
    {
        img: serviceImage5,
        title: 'Rehabilitación Oral con Sistema CAD-CAM',
        text: 'El uso CAD – CAM nos permite diseñar mediante un programa de ordenador las piezas dentales.',
    },
    {
        img: serviceImage6,
        title: 'Endodoncia',
        text: 'Si tienes un diente muy dañado o una infección seria, es posible que necesites este tratamiento. Las endodoncias se utilizan para reparar y salvar tu diente en vez de extraerlo.',
    },
]
export default function Services() {
    return (
        <div id="services" className="mt-100">
            <div className="container" style={{ maxWidth: '922px' }}>
                <p className="text-blue font-manrope font-bold text-blue-brand text-center">
                    Servicios odontológicos
                </p>
                <h2 className="mt-4 font-bold text-3xl text-center">
                    Conoce los servicios que servicios en Dental Baldwin
                </h2>
                <div className="grid lg:grid-cols-3 place-items-center gap-10 mt-16">
                    {servicesList.map((element, index) => (
                        <ServiceCard
                            data={element}
                            key={`service-item-${index}`}
                        />
                    ))}
                </div>
                <a
                    target={'_blank'}
                    href="https://wa.me/51989868701?text=%C2%A1Hola!%20Acabo%20de%20visitar%20tu%20p%C3%A1gina%20y%20tengo%20una%20consulta.%20%F0%9F%91%8B"
                    className="mt-12 mx-auto flex mt-6 bg-blue-brand rounded-primary w-max px-4 py-2 pointer text-white font-monrope hover:shadow-lg"
                >
                    Contáctame para una cita
                    <img src={wsp} alt="wsp" className="ml-2" />
                </a>
            </div>
        </div>
    )
}
