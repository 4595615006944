import React from 'react'

export default function TestimoniesCard({ data }) {
    return (
        <div className="flex lg:h-36 max-w-lg">
            <img
                className="rounded-full w-12 h-12 mr-4 shrink-0"
                src={data.image}
                alt={'testimonio'}
            />
            <div className="flex flex-col justify-between text-black-brand">
                <p>{data.text}</p>
                <div>
                    <h5 className="text-xl font-bold text-black-brand">
                        {data.name}
                    </h5>
                    <p className="mt-4 text-sm text-gray-brand font-bold">{data.job}</p>
                </div>
            </div>
        </div>
    )
}
