import * as React from 'react'
import Hero from '../components/hero'
import Profile from '../components/profile'
import Features from '../components/features'
import Services from '../components/services'
import Testimonies from '../components/testimonies'
// image
import Image from '../images/header-logo.png'
import { Helmet } from 'react-helmet'

// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Dental Baldwin</title>
                <meta content='Bienvenidos a Dental Baldwin. Ofrecemos servicios odontológicos restauradores, tratamientos quirúrgicos e implantes dentales.' name='description' />
                <meta name="og:type" content="website"/>
                <meta name="og:title" content="Dental Baldwin"/>
                <meta name="og:image" content={Image}/>

                <meta name="og:description" content="Bienvenidos a Dental Baldwin. Ofrecemos servicios odontológicos restauradores, tratamientos quirúrgicos e implantes dentales." />
                <meta name="twitter:card" content="summary_large_image"/>

            </Helmet>
            <div className="overflow-x-hidden">
                <Hero />
                <Features />
                <Profile />
                <Services />
                <Testimonies />
            </div>
        </>
    )
}

export default IndexPage
