import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

// images
import testimonieImage1 from './../../../images/testimony-image-1.png'
import testimonieImage2 from './../../../images/testimony-image-2.png'
import TestimoniesCard from '../testimoniesCard'
import RightArrow from '../../../images/swiper-right-arrow.png'
import LeftArrow from '../../../images/swiper-left-arrow.png'

// Import Swiper styles
import 'swiper/css'
const testimoniesList = [
    {
        image: testimonieImage1,
        text: '“No lo cambio por nada del mundo :d”',
        name: 'Alexander Gutierrez',
        job: 'Estudiante',
    },
    {
        image: testimonieImage2,
        text: `“Healthycarely is an website and mobile app
    for you to feel better or get medical help.
    We offer you a 24/7 doctor service with no
    appointment needed”`,
        name: 'Milagros Salvador ',
        job: 'Ama de casa',
    },
]
export default function TestimoniesSwiper() {
    return (
        <div>
            <Swiper
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                    },
                  }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                navigation={{
                    prevEl: '.prev',
                    nextEl: '.next',
                }}
            >
                {testimoniesList.map((element, index) => (
                    <SwiperSlide
                        className="h-52"
                        key={`testimony-item-${index}`}
                    >
                        <TestimoniesCard data={element} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
